import Const from '../constants'
import axiosApiInstance from '../interceptors'

class PrivaryPolicyService {
  getPrivaryPolicy() {
    return axiosApiInstance.get(Const.api_url + 'dashboard/privacy-policy')
  }
}

export default new PrivaryPolicyService()
