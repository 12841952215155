














import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import PrivaryPolicyService from '@/services/PrivaryPolicyService'

@Component({
  components: {
    LayoutCompact
  }
})
export default class PrivaryPolicy extends Vue {
  public privaryPolicy: any = {}

  created() {
    this.fetchPrivaryPolicy()
  }

  private fetchPrivaryPolicy() {
    PrivaryPolicyService.getPrivaryPolicy().then(
      (response: any) => {
        this.privaryPolicy = response.data.items
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        this.privaryPolicy =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
      }
    )
  }
}
